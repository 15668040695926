<template>
  <CardConf
    ref="card"
    :title="$t('boxes.boxs')"
    :icon="'$boxes'"
    :size="'600'"
    :keys="'box_active'"
    :activeted="false"
    :disabled="!$store.getters['auth/getPlan']('boxes')"
  >
    <template>
      <v-row align="center">
        <v-col cols="12" md="10">
          {{ $t("boxes.boxes_obligatoris") }}
        </v-col>
        <v-col class="pt-1" cols="11" md="2">
          <v-select
            :items="items"
            single-line
            dense
            outlined
            hide-details
            v-model="boxes_obligatoris"
          ></v-select>
          <v-tooltip v-if="!$vuetify.breakpoint.mdAndDown" bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="$vuetify.breakpoint.smAndDown ? '' : on"
                :style="' position: absolute; right: 8px; top: 80px'"
                color="red"
                >mdi-information-outline</v-icon
              >
            </template>

            <span>
              El estudio o artista deberán asignar un box a las citas que se
              crean de forma obligatoria</span
            >
          </v-tooltip>
          <v-menu
            v-if="$vuetify.breakpoint.mdAndDown"
            :close-on-content-click="true"
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                :style="' position: absolute; right: 8px; top: 160px'"
                color="red"
                >mdi-information-outline</v-icon
              >
            </template>
            <p class="py-3 mb-0 px-5" style="font-size: 12px">
              <span>
                El estudio o artista deberán asignar un box a las citas que se
                crean de forma obligatoria</span
              >
            </p></v-menu
          >
        </v-col>
      </v-row>
      <v-row id="boxes" class="payment" align="center">
        <v-col cols="12" md="10">
          {{ $t("boxes.price_conf") }}
        </v-col>

        <v-col class="pt-1" cols="11" md="2">
          <v-select
            :items="items"
            dense
            outlined
            hide-details
            v-model="box_price"
          ></v-select>
          <v-tooltip
            v-if="!$vuetify.breakpoint.mdAndDown"
            class="tool"
            v-model="showInfo2"
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-icon
                :style="' position: absolute; right: 8px; top: 144px'"
                color="red"
                v-on="on"
                >mdi-information-outline</v-icon
              >
            </template>
            <span style="font-size: 12px">
              <span
                >Activando esta opción, darás la posibilidad al artista de
                reservar directamente un BOX disponible.</span
              >
            </span>
          </v-tooltip>
          <v-menu
            v-if="$vuetify.breakpoint.mdAndDown"
            :close-on-content-click="true"
          >
            <template v-slot:activator="{ on }">
              <v-icon
                :style="' position: absolute; right: 8px; top: 295px'"
                color="red"
                v-on="on"
                >mdi-information-outline</v-icon
              >
            </template>
            <p class="py-3 mb-0 px-5" style="font-size: 12px">
              <span
                >Activando esta opción, darás la posibilidad al artista de
                reservar directamente un BOX disponible.</span
              >
            </p></v-menu
          >
        </v-col>
      </v-row>
      <v-row class="payment" align="center">
        <v-col cols="12" md="10">
          {{ $t("boxes.token_payment_conf") }}
        </v-col>

        <v-col class="pt-1" cols="11" md="2">
          <v-select
            :items="items"
            single-line
            dense
            outlined
            :disabled="
              this.$store.getters['auth/getSetting']('pm_stripe_id') === 0 ||
              box_price === 0
            "
            hide-details
            v-model="payment_boxes"
          ></v-select>
          <v-tooltip
            v-if="!$vuetify.breakpoint.mdAndDown"
            v-model="showInfo1"
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="$vuetify.breakpoint.smAndDown ? '' : on"
                :style="' position: absolute; right: 8px; top: 210px'"
                color="red"
                >mdi-information-outline</v-icon
              >
            </template>
            <span
              style="font-size: 12px"
              v-if="
                this.$store.getters['auth/getSetting']('pm_stripe_id') === 0
              "
            >
              Para poder activar el metodo paga y señal debes sincronizar stripe
            </span>

            <span v-else
              >Activando esta opcion el artista debera depositar una cantidad
              que fijes para hacer la reserva de un box</span
            >
          </v-tooltip>
          <v-menu
            v-if="$vuetify.breakpoint.mdAndDown"
            :close-on-content-click="true"
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                :style="' position: absolute; right: 8px; top: 440px'"
                color="red"
                >mdi-information-outline</v-icon
              >
            </template>
            <p class="py-3 mb-0 px-5" style="font-size: 12px">
              <span
                style="font-size: 12px"
                v-if="
                  this.$store.getters['auth/getSetting']('pm_stripe_id') === 0
                "
              >
                Para poder activar el metodo paga y señal debes sincronizar
                stripe
              </span>

              <span v-else
                >Activando esta opcion el artista debera depositar una cantidad
                que fijes para hacer la reserva de un box</span
              >
            </p></v-menu
          >
        </v-col>
      </v-row>
    </template>
  </CardConf>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "BoxesCard",
  components: {
    CardConf: () => import("@/components/user/settings/Card"),
  },

  methods: {
    ...mapActions("users", ["settingUpdate", "getSetting"]),
    edit(valor) {
      this.disabled = valor;
    },
  },
  data() {
    return {
      disabled: false,
      showInfo1: false,
      showInfo2: false,
      items: [
        { text: this.$t("yes"), value: 1 },
        { text: this.$t("no"), value: 0 },
      ],
    };
  },
  computed: {
    payment_boxes: {
      get() {
        return this.$store.getters["auth/getSetting"]("payment_box");
      },
      set(value) {
        //llamamos a la funcion de crear setting
        this.settingUpdate({ key: "payment_box", value }).then((data) => {
          //console.log(data);
          if (value) this.$alert(this.$t("alert_payment_box"), "", "");
        });
      },
    },
    box_price: {
      get() {
        return this.$store.getters["auth/getSetting"]("box_price");
      },
      set(value) {
        this.settingUpdate({ key: "box_price", value }).then(() => {
          this.$alert(this.$t("save_ok"));
          this.payment_boxes = false;
          //console.log(data);
        });
      },
    },
    boxes_obligatoris: {
      get() {
        return this.$store.getters["auth/getSetting"]("boxes_obligatoris");
      },
      set(value) {
        this.settingUpdate({ key: "boxes_obligatoris", value }).then(() => {
          this.$alert(this.$t("save_ok"));
          //console.log(data);
        });
      },
    },
  },
};
</script>


