var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CardConf',{ref:"card",attrs:{"title":_vm.$t('boxes.boxs'),"icon":'$boxes',"size":'600',"keys":'box_active',"activeted":false,"disabled":!_vm.$store.getters['auth/getPlan']('boxes')}},[[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_vm._v(" "+_vm._s(_vm.$t("boxes.boxes_obligatoris"))+" ")]),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"11","md":"2"}},[_c('v-select',{attrs:{"items":_vm.items,"single-line":"","dense":"","outlined":"","hide-details":""},model:{value:(_vm.boxes_obligatoris),callback:function ($$v) {_vm.boxes_obligatoris=$$v},expression:"boxes_obligatoris"}}),(!_vm.$vuetify.breakpoint.mdAndDown)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({style:(' position: absolute; right: 8px; top: 80px'),attrs:{"color":"red"}},_vm.$vuetify.breakpoint.smAndDown ? '' : on),[_vm._v("mdi-information-outline")])]}}],null,false,59461460)},[_c('span',[_vm._v(" El estudio o artista deberán asignar un box a las citas que se crean de forma obligatoria")])]):_vm._e(),(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-menu',{attrs:{"close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({style:(' position: absolute; right: 8px; top: 160px'),attrs:{"color":"red"}},on),[_vm._v("mdi-information-outline")])]}}],null,false,324460570)},[_c('p',{staticClass:"py-3 mb-0 px-5",staticStyle:{"font-size":"12px"}},[_c('span',[_vm._v(" El estudio o artista deberán asignar un box a las citas que se crean de forma obligatoria")])])]):_vm._e()],1)],1),_c('v-row',{staticClass:"payment",attrs:{"id":"boxes","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_vm._v(" "+_vm._s(_vm.$t("boxes.price_conf"))+" ")]),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"11","md":"2"}},[_c('v-select',{attrs:{"items":_vm.items,"dense":"","outlined":"","hide-details":""},model:{value:(_vm.box_price),callback:function ($$v) {_vm.box_price=$$v},expression:"box_price"}}),(!_vm.$vuetify.breakpoint.mdAndDown)?_c('v-tooltip',{staticClass:"tool",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({style:(' position: absolute; right: 8px; top: 144px'),attrs:{"color":"red"}},on),[_vm._v("mdi-information-outline")])]}}],null,false,2895246492),model:{value:(_vm.showInfo2),callback:function ($$v) {_vm.showInfo2=$$v},expression:"showInfo2"}},[_c('span',{staticStyle:{"font-size":"12px"}},[_c('span',[_vm._v("Activando esta opción, darás la posibilidad al artista de reservar directamente un BOX disponible.")])])]):_vm._e(),(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-menu',{attrs:{"close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({style:(' position: absolute; right: 8px; top: 295px'),attrs:{"color":"red"}},on),[_vm._v("mdi-information-outline")])]}}],null,false,2937203027)},[_c('p',{staticClass:"py-3 mb-0 px-5",staticStyle:{"font-size":"12px"}},[_c('span',[_vm._v("Activando esta opción, darás la posibilidad al artista de reservar directamente un BOX disponible.")])])]):_vm._e()],1)],1),_c('v-row',{staticClass:"payment",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_vm._v(" "+_vm._s(_vm.$t("boxes.token_payment_conf"))+" ")]),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"11","md":"2"}},[_c('v-select',{attrs:{"items":_vm.items,"single-line":"","dense":"","outlined":"","disabled":this.$store.getters['auth/getSetting']('pm_stripe_id') === 0 ||
            _vm.box_price === 0,"hide-details":""},model:{value:(_vm.payment_boxes),callback:function ($$v) {_vm.payment_boxes=$$v},expression:"payment_boxes"}}),(!_vm.$vuetify.breakpoint.mdAndDown)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({style:(' position: absolute; right: 8px; top: 210px'),attrs:{"color":"red"}},_vm.$vuetify.breakpoint.smAndDown ? '' : on),[_vm._v("mdi-information-outline")])]}}],null,false,706485839),model:{value:(_vm.showInfo1),callback:function ($$v) {_vm.showInfo1=$$v},expression:"showInfo1"}},[(
              this.$store.getters['auth/getSetting']('pm_stripe_id') === 0
            )?_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" Para poder activar el metodo paga y señal debes sincronizar stripe ")]):_c('span',[_vm._v("Activando esta opcion el artista debera depositar una cantidad que fijes para hacer la reserva de un box")])]):_vm._e(),(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-menu',{attrs:{"close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({style:(' position: absolute; right: 8px; top: 440px'),attrs:{"color":"red"}},on),[_vm._v("mdi-information-outline")])]}}],null,false,387475453)},[_c('p',{staticClass:"py-3 mb-0 px-5",staticStyle:{"font-size":"12px"}},[(
                this.$store.getters['auth/getSetting']('pm_stripe_id') === 0
              )?_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" Para poder activar el metodo paga y señal debes sincronizar stripe ")]):_c('span',[_vm._v("Activando esta opcion el artista debera depositar una cantidad que fijes para hacer la reserva de un box")])])]):_vm._e()],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }